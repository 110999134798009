.mapboxgl-ctrl-legend {
    position: relative;
    left: 10px;
    max-height: 100%;
    max-width: 100%;
    bottom: 10px;
    /*overflow: auto;*/
    background-color: white;
}

#custom-legend {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: relative;
    bottom: 10px;
}

.custom-legend-titleElement {
    text-align: left;
    margin-bottom: 5px;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-weight: bold;
}

.custom-legend-colorElement {
    display: inline-block;
    border-radius: 20%;
    width: 0.9em;
    height: 0.9em;
}

.custom-legend-colorElement-scale-ini {    
    border-top-left-radius: 40%;
    border-bottom-left-radius: 40%;
    width: 0.2em;
    height: 0.9em;
    float: left;
}

.custom-legend-colorElement-scale-end {
    border-top-right-radius: 40%;
    border-bottom-right-radius: 40%;
    width: 0.2em;
    height: 0.9em;
    float: left;
}

.custom-legend-colorElement-scale-inside {    
    width: 0.2em;
    height: 0.9em;
    float: left;
}
.custom-legend-variableElement {
    margin-left: 0.5em;
}

#custom-legend-itemsContainer {
    margin-left: 0.5em;
    margin-right: 0.5em;
}
