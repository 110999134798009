.iframe-html {
    display: block;
    width: 100%;
    height: 100vh;
    border-style: none;
}

.error {
    background-color: #FFF3F3;
    border-style: solid;
    border-width: 2px 0 2px 0;
    border-top-color: #FFAAAA;
    border-bottom-color: #FFAAAA
}

.message, .warning {
    background-color: #fbffd5;
    border-style: solid;
    border-width: 2px 0 2px 0;
    border-top-color: #ffe778;
    border-bottom-color: #ffe778
}

.input-number {
    width: 140px;
}
