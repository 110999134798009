/** PREVIEW FOR MOBILE **/
.smartphone {
    position: relative;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The mobile device */
.smartphone {
    width: calc(360px + 32px);
    height: calc(640px + 120px);
}

/* The screen (or content) of the device */
.smartphone .content {
    width: 360px;
    height: 640px;
    background: #E5E5E5;
}

.smartphone .content .formItems {
    max-height: calc(640px - 45px);
}


/* The device with borders */
.tablet {
    position: relative;
    width: 768px;
    height: 1024px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}

/* The horizontal line on the top of the device */
.tablet:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}

/* The circle on the bottom of the device */
.tablet:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet .content {
    width: calc(768px - 32px);
    height: 1024px;
}

.tablet .content .formItems {
    max-height: calc(904px - 45px);
}

.devicePreview .content h1 {
    min-height: 35px;
    line-height: 35px;
    background: #295F48;
    color: #FFF;
    margin-bottom: 0px;
}


/* ITEMS */
.devicePreview .content .formItems {
    padding: 5px;
    text-align: left;
    overflow-x: hidden;
    overflow-y: overlay;
    background-color: #E8F0F2;
}

.devicePreview .content .formItems .item {
    margin: 7px 7px 7px 7px;
}

.devicePreview .content .formItems .backgroundWhite {
    padding: 10px;
    background: #FFF;
}

.devicePreview .content .formItems .buttonFull {
    width: 100%;
    background: #295F48;
    color: #FFF;
    overflow-x: hidden;
}

.devicePreview .content .formItems .input,
.devicePreview .content .formItems .textArea,
.devicePreview .content .formItems .item>label,
.devicePreview .content .formItems .item .ant-radio-group,
.devicePreview .content .formItems .item .ant-radio-group>label,
.devicePreview .content .formItems .checkbox {
    margin-bottom: 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    margin-left: 0px;
}

.devicePreview .content .formItems .checkbox {
    margin-top: 10px;
}

.devicePreview .content .formItems .input:hover,
.devicePreview .content .formItems .input:focus,
.devicePreview .content .formItems .textArea:hover,
.devicePreview .content .formItems .textArea:focus {
    box-shadow: none;
    outline: none;
}

.devicePreview .content .formItems .bloque .item {
    padding-left: 20px;
}

.devicePreview .content .formItems .bloque .bloque-buttons {
    float: right;
}

.devicePreview .content .formItems .bloque .bloque-buttons>* {
    font-size: 20px;
    background-color: #FFF;
    color: #295F48;
}

.devicePreviewButtons {
    padding-bottom: 10px;
}

/*.devicePreview ::-webkit-scrollbar {
    width: 3px;
    background-color: #000;
}

.devicePreview ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    background: rgba(0, 0, 0, 1);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
}

.devicePreview ::placeholder {
    color: #909090;
    opacity: 1;
    font-size: 90%;
}

.devicePreview :-ms-input-placeholder {
    color: #909090;
    font-size: 90%;
}

.devicePreview ::-ms-input-placeholder {
    color: #909090;
    font-size: 90%;
}*/

/* NEW LOOK&FEEL */
/* Common */
.devicePreview .content .formItems .backgroundWhite {
    background-color: transparent;
    position: relative;
    padding: 10px 0;
}
.devicePreview .content .formItems .item {
    margin: 7px 14px 7px 7px;
}
.devicePreview .content .formItems .item .itemLabel, .devicePreview .content .formItems .item div.checkbox {
    font-weight: bold;
}
.devicePreview .content .formItems .item .ant-picker-input input[value=""] {
    text-align: end;
}
.devicePreview .content .formItems .input, .devicePreview .content .formItems .textArea, .devicePreview .content .formItems .item>label, .devicePreview .content .formItems .item .ant-radio-group, .devicePreview .content .formItems .item .ant-radio-group>label, .devicePreview .content .formItems .checkbox {
    margin-bottom: 0;
}

/* Form Name */
.devicePreview .content h1 {
    min-height: 45px;
    line-height: 45px;
    background: #053742;
}

/* Header */
.devicePreview .content .formItems .item h2 {
    border: 2px solid #DDDDDD;
    border-left: none;
    border-right: none;
    font-weight: bold;
    margin-bottom: 0;
}
.devicePreview .content .formItems .item h2.small {
    font-size: inherit !important;
}
.devicePreview .content .formItems .item h2.medium {
    font-size: 35px !important;
}
.devicePreview .content .formItems .item h2.large {
    font-size: 50px !important;
}

/* Checbox and Radio Button */
.devicePreview .content .formItems .item>label.ant-checkbox-wrapper.checkbox {
    display: flex;
    flex-direction: row-reverse;
}
.devicePreview .content .formItems .item>label.ant-checkbox-wrapper.checkbox > span:not(.ant-checkbox) {
    padding: 0;
    flex-grow: 1;
}
.devicePreview .content .formItems .item>label.ant-checkbox-wrapper:not(.checkbox),
.devicePreview .content .formItems .item .ant-radio-group>label.ant-radio-wrapper {
    background: #FEFFFE;
    padding: 0px 15px;
}
.devicePreview .content .formItems .item>label.ant-checkbox-wrapper:not(.checkbox):last-child,
.devicePreview .content .formItems .item .ant-radio-group>label.ant-radio-wrapper:last-child {
    padding-bottom: 0px;
    border-bottom: 1px solid #DDDDDD;
}
.devicePreview .content .formItems .item>label.ant-checkbox-wrapper:not(.checkbox):last-child > span:not(.ant-checkbox),
.devicePreview .content .formItems .item .ant-radio-group>label.ant-radio-wrapper:last-child > span:not(.ant-checkbox) {
    border-color: transparent;
}
.devicePreview .content .formItems .item>label.ant-checkbox-wrapper:not(.checkbox) > span + span,
.devicePreview .content .formItems .item .ant-radio-group>label.ant-radio-wrapper > span + span {
    border-bottom: 1px solid #DDDDDD;
    padding: 15px 0 15px 0;
    margin-left: 10px;
    width: inherit;
}
.devicePreview .content .formItems .item .ant-radio-group>label.ant-radio-wrapper {
    flex-flow: row-reverse;
}
.devicePreview .content .formItems .item .simpleCheckbox {
    box-sizing: border-box;
    margin: auto;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    display: flex;
    align-items: center;
    line-height: unset;
}
.devicePreview .content .formItems .item .simpleCheckbox label {
    flex-grow: 1;
}
/* Select */
.devicePreview .content .formItems .item .customSelect :not(.itemLabel){
    text-align: end;
}
/* Block */
.devicePreview .content .formItems .bloque {
    border: 3px solid #B1AAAA;
    margin: 7px 14px 7px 7px;
}
.devicePreview .content .formItems .bloque .bloque-title {
    padding: 5px 10px;
    background: #FFF;
    border-bottom: 1px solid #DDDDDD;
}
.devicePreview .content .formItems .bloque .bloque-title span.anticon {
    float: right;
    margin: 5px 0 0 0;
}
.devicePreview .content .formItems .bloque:first-child .bloque-title .anticon {
    visibility: hidden;
}
.devicePreview .content .formItems .bloque .item {
    padding-left: 0px;    
    margin: 7px;
}