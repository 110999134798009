.custom-popup-divContainerElement {
    overflow: auto;
    max-height: 300px;
    max-width: 400px;
    --lateralMargins: 5px;
    margin-left: var(--lateralMargins);
    margin-right: var(--lateralMargins);
}

.popup-cases-1234 {
    z-index: 10 !important;
}

.custom-popup-titleElement {
    margin: 0 var(--lateralMargins) 0 0;
    text-align: left;
}

.custom-popup-title {
    text-align: left;
    font-weight: bold;
    font-size: 0.9em !important;
}

.custom-popup-lineElement {
    margin: 1px calc(var(--lateralMargins) - 1) 1px var(--lateralMargins);
}

.custom-popup-alias {
    font-size: 0.8em !important;
    font-weight: bold;
}

.custom-popup-value {
    font-size: 0.8em !important;
    margin-right: var(--lateralMargins) !important;
}

.custom-popup-pElement, .custom-popup-imgDivContainer, .custom-popup-imageElement {
    margin-bottom: unset !important;
    text-align: left !important;
}

.mapboxgl-popup-content {
    box-shadow: 8px 9px 8px rgba(0, 0, 0, 0.85) ;
}

