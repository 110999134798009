.mapboxgl-ctrl-activeLayerLabel {
    position: relative;
    left: 10px;
    max-height: 100%;
    max-width: 100%;
    top: 10px;
    overflow: auto;
    background-color: white;
}

.labelContainer {
    margin: 0.5ch 0.5ch 0.5ch 0.5ch;
}

.activeLayerLabelElement {
    font-weight: bold;
}