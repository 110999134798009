@import '~antd/dist/antd.css';

.site-layout-background {
    background: #fff;
}

.ant-layout-header {
    padding: 0;
}

.ant-menu {
    font-size: 18px;
}

.ant-table {
    font-size: 16px;
}

.logo {
    float: left;
    margin-left: 5px;
    font-size: 24px;
    color: white;
}

.spinner-overlay {
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-container {
    display: inline-block;
    width: 50px;
    height: 50px;
}

.login-center {
    height: fit-content;
    width: 490px;
    background-color: white;

    position: center;
    padding: 10px;
    top: 150px;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}

.ant-form-item-label {
    white-space: pre-line;
}

/* FONT SIZE */
* {
    font-size: calc(12px + 0.4vw) !important;
    font-family: "Lato", Arial, Helvetica, sans-serif;
}

.ant-table-column-sorter-inner > * > * {
    font-size: 15px !important;
    max-width: 15px;
}

button:not(.ant-switch, .ant-table-row-expand-icon) {
    line-height: 30px !important;
    min-height: 40px;
}

.ant-table-pagination>* {
    line-height: 40px;
    min-height: 40px;
}

/* Botón + en tablas row expand (forms data) */
button.ant-table-row-expand-icon{
    color: inherit;
    border-color: #000;
    transform: scale(1.25);
}
.actionsIcons > a, .actionsIcons > span {
    margin-right: 5px;
}