/*
VA-Team
 */

.mapboxgl-ctrl.div-ctrl{
    width: 30px !important;
    height: 40px !important;
}

/*
Icon for mapbox control 'map-styles'
*/
span.mapbox-ctrl-icon.span.map-styles {
    background-image: url(ios-globe.svg);
}

