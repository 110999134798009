/*
VA-Team
 */

.mapboxgl-ctrl.div-ctrl{
    width: 30px !important;
    height: 40px !important;
}

/*
Icon for mapbox control 'map-color-schemes'
*/
span.mapbox-ctrl-icon.span.map-color-schemes {
    background-image: url(map-color-schemes-logo-ios-color-palette.svg);
}

/*
Icon for mapbox control 'map-layers '
*/
span.mapbox-ctrl-icon.span.map-layers {
    background-image: url(map-layers-logo-buffer.svg);
}

/*
Icon for mapbox control 'map-styles'
*/
span.mapbox-ctrl-icon.span.map-styles {
    background-image: url(map-styles-logo-ios-globe.svg);
}


